import { ComponentPropsWithoutRef, ReactNode } from 'react';
import useModal from '@/modals/hooks/useModal';

type ConfirmButtonType = {
  children: ReactNode;
  description?: string | ReactNode;
  expectedInput?: string;
  omitInput?: boolean;
  selectInputOptions?: any[];
  confirmCTA?: string;
  action: (value?: unknown) => void;
} & ComponentPropsWithoutRef<'button'>;

function ConfirmButton({
  children,
  description = '',
  expectedInput = 'CONFIRM',
  omitInput = false,
  selectInputOptions = null,
  confirmCTA = 'Confirm',
  action,
  ...buttonProps
}: ConfirmButtonType) {
  const modal = useModal('ConfirmModal');

  function onShowConfirmModal() {
    modal.show({
      props: {
        expectedInput,
        omitInput,
        selectInputOptions,
        confirmCTA,
        description,
        action,
      },
    });
  }

  // Render
  return (
    <button type="button" onClick={onShowConfirmModal} {...buttonProps}>
      {children}
    </button>
  );
}

export default ConfirmButton;
